import React, { Component } from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import Dropzone from "react-dropzone";
import MediaQuery from "react-responsive";
import classNames from "classnames";
import { ApiConfig } from "../../../constants/ApiConfig";
import "./_modal-uploader.scss";
import ConsentCheckbox from "../ConsentCheckbox/ConsentCheckbox";
import CloseModalButton from "../CloseModalButton/CloseModalButton";
import ContactDetails from "../ContactDetails/ContactDetails";
import RecruitmentTask from "../RecruitmentTask/RecruitmentTask";
import SendButton from "../SendButton/SendButton";
import consents from "../../../data/consents.json";
import { handleEmailValidation, handleNameValidation } from "./Validation";
import { withHistoryLocation, withNavigate } from "../../helpers";

const propTypes = {
  cvModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedOffer: PropTypes.any,
  offersFilters: PropTypes.shape({
    cities: PropTypes.arrayOf(PropTypes.string),
    technologies: PropTypes.arrayOf(PropTypes.string),
  }),
  selectedCategory: PropTypes.any,
};

class ModalUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phase: 0,
      files: null,
      sending: 0,
      progress: 0,
      dragged: false,
      personalConsentForFutureOffers: false,
      personalConsentForPrivacyPolicy: false,
      email: "",
      date: "",
      validEmail: false,
      emailValidationMsg: "",
      name: "",
      validName: false,
      nameValidationMsg: "",
      surname: "",
      validSurname: false,
      surnameValidationMsg: "",
      validDate: false,
      dateValidationMsg: "",
      currentOfferSeoSlug: "",
      traking: false,
    };
    this.dropzoneStyle = {};
    this.uploadBtn = React.createRef();
    this.sendButton = React.createRef();
    this.closeButton = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    nextProps.selectedOffer &&
      this.setState({ currentOfferSeoSlug: nextProps.selectedOffer.seoSlug });
    if (nextProps.cvModalOpen != null && nextProps.cvModalOpen === true) {
      this.modalShow();
    } else {
      this.modalHide();
    }

    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.modalHide();
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.state.phase !== nextState.phase || this.state.files !== nextState.files) {
      document.querySelector(".Modal") && (document.querySelector(".Modal").scrollTop = 0);
    }
  }

  onDrop = (files) => {
    if (this.state.sending === 2) {
      return false;
    }
    requestAnimationFrame(() => {
      this.setState({ phase: 1 });
    });
    if (files.length > 0) {
      this.setState({
        files,
        sending: 2,
        phase: 1,
        dragged: false,
      });
    } else {
      requestAnimationFrame(() => {
        this.setState({ phase: 3 });
      });
    }
  };

  modalShow = (args) => {
    document.body.style.overflow = "hidden";
    this.setState({
      phase: 1,
    });
    if (args && args.files) {
      this.onDrop(args.files);
    }
    requestAnimationFrame(() => {
      this.uploadBtn.current.focus();
    });
  };

  modalHide = (e) => {
    document.body.style.overflow = "unset";
    if (e) {
      e.preventDefault();
    }
    requestAnimationFrame(() => {
      this.setState({
        phase: 0,
        files: null,
        sending: 0,
        progress: 0,
        personalConsentForFutureOffers: false,
        personalConsentForPrivacyPolicy: false,
        email: "",
        validEmail: false,
        emailValidationMsg: "",
        date: "",
        validDate: false,
        dateValidationMsg: "",
        name: "",
        validName: false,
        nameValidationMsg: "",
        surname: "",
        validSurname: false,
        surnameValidationMsg: "",
      });
      this.props.closeModal();
    });
  };

  isSendingDone = () => this.state.phase >= 1 && this.state.sending === 2;

  sendCv = () => {
    const formData = new FormData();
    formData.append("cv-file", this.state.files[0]);

    if (
      this.props.selectedOffer === null &&
      this.props.offersFilters.cities.length === 0 &&
      this.props.offersFilters.technologies.length === 0 &&
      this.props.selectedCategory === null
    ) {
      formData.append(
        "general-info",
        "Nadawca maila nie ustawił żadnych filtrów i nie wybrał żadnej oferty pracy."
      );
    }

    if (this.props.selectedOffer) {
      formData.append("city", this.props.selectedOffer.cities.join());
      formData.append("job-title", this.props.selectedOffer.title);
      formData.append("target-mail", this.props.selectedOffer.targetMail);
    }

    if (this.props.offersFilters) {
      formData.append("offers-filters-cities", this.props.offersFilters.cities.join(" "));
      formData.append(
        "offers-filters-technologies",
        this.props.offersFilters.technologies.join(" ")
      );
    }

    if (this.props.selectedCategory) {
      formData.append("selected-category", this.props.selectedCategory.name);
    }

    formData.append("future-offers-consents", this.state.personalConsentForFutureOffers);
    formData.append("privacy-policy-consents", this.state.personalConsentForPrivacyPolicy);
    formData.append("email", this.state.email);
    formData.append("name", this.state.name);
    formData.append("surname", this.state.surname);

    if (this.state.date) {
      formData.append("date-to-send-a-task", this.state.date);
    }

    $.ajax({
      url: `${ApiConfig.BACKEND_URL}/wp-json/sender/v2/cv`,
      type: "POST",
      xhr: () => {
        const myXhr = $.ajaxSettings.xhr();
        if (myXhr.upload) {
          myXhr.upload.addEventListener("progress", this.progressHandlingFunction, false);
        }
        return myXhr;
      },
      beforeSend: null,
      success: this.sendSuccess,
      error: this.sendError,
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
    });
  };

  sendSuccess = () => {
    this.props.navigate("/thank-you", {
      state: {
        tracking: {
          isActive:
            this.props.selectedOffer && this.props.selectedOffer.details.tracking.length > 0,
          offer: this.props.selectedOffer,
        },
      },
    });

    this.modalHide();
  };

  sendError = () => {
    requestAnimationFrame(() => {
      this.setState({
        phase: 3,
        sending: 0,
      });
    });
  };

  progressHandlingFunction = (e) => {
    if (e.lengthComputable) {
      this.setState({
        progress: `${(e.loaded / e.total) * 100}%`,
      });
    }
  };

  dragEnter = () => {
    this.setState({ dragged: true });
  };

  dragLeave = () => {
    this.setState({ dragged: false });
  };

  openDropzone = (e) => {
    e.preventDefault();
    this.setState({
      phase: 1,
    });
    if (this.state.sending === 2) {
      this.setState({
        sending: 0,
      });
    }
    requestAnimationFrame(() => this.dropzoneRef.open());
  };

  removeFiles = () => {
    this.setState({
      files: null,
      phase: 1,
      sending: 0,
      dragged: false,
    });

    setTimeout(() => {
      this.uploadBtn.current.focus();
    });
  };

  handleEmailChange = (e) => {
    const { msg, isValid } = handleEmailValidation(e.target.value);
    this.setState({
      email: e.target.value,
      validEmail: isValid,
      emailValidationMsg: msg,
    });
  };

  handleNameChange = (e) => {
    const { msg, isValid } = handleNameValidation(e.target.value);
    this.setState({
      name: e.target.value,
      validName: isValid,
      nameValidationMsg: msg,
    });
  };

  handleSurnameChange = (e) => {
    const validName = handleNameValidation(e.target.value);
    this.setState({
      surname: e.target.value,
      validSurname: validName.isValid,
      surnameValidationMsg: validName.msg,
    });
  };

  handleDateChange = (e) => {
    this.setState({
      date: e.target.value,
      validDate: this.validDate(e.target.value),
      dateValidationMsg: this.validDate(e.target.value) ? "" : "Date is incorrect",
    });
  };

  validDate = (value) => {
    const givenDate = new Date(value);
    const currentDate = new Date(this.getCurrentDate());

    return +givenDate >= +currentDate;
  };

  getCurrentDate() {
    let today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();

    today = `${year}-${month}-${day}`;

    return today;
  }

  validFileLoaded = () => this.state.phase === 1 && this.state.files && this.state.sending === 2;

  isSendError = () => this.state.phase === 3;

  isReadyToLoadFile = () => this.state.phase === 1 || this.state.phase === 3;

  handleClickPersonalConsentForFutureOffersCheckbox = () => {
    this.setState({
      personalConsentForFutureOffers: !this.state.personalConsentForFutureOffers,
    });
  };

  handleClickPersonalConsentForPrivacyPolicyCheckbox = () => {
    this.setState({
      personalConsentForPrivacyPolicy: !this.state.personalConsentForPrivacyPolicy,
    });
  };

  isSendDisabled = () => {
    const offersConsents =
      !this.state.personalConsentForFutureOffers || !this.state.personalConsentForPrivacyPolicy;
    const validData = !this.state.validName || !this.state.validSurname || !this.state.validEmail;
    const validCVFile = !(this.state.files ? this.state.files.length > 0 : false);
    let validDate = false;

    if (this.props.selectedOffer ? this.props.selectedOffer.showDatePicker : false) {
      validDate = !this.state.validDate;
    }

    return offersConsents || validData || validCVFile || validDate;
  };

  onTab = (event) => {
    const isTab = event.key === "Tab" || event.keyCode === 9;
    if (!isTab) {
      return;
    }

    if (event.shiftKey && !this.isSendDisabled() && event.target === this.uploadBtn.current) {
      this.sendButton.current.focus();
      event.preventDefault();
      return;
    }

    if (event.shiftKey && !this.isSendDisabled() && event.target === this.sendButton.current) {
      this.uploadBtn.current.focus();
      event.preventDefault();
      return;
    }

    if (event.shiftKey && this.isSendDisabled() && event.target === this.uploadBtn.current) {
      this.closeButton.current.focus();
      event.preventDefault();
      return;
    }

    if (event.shiftKey && this.isSendDisabled() && event.target === this.closeButton.current) {
      this.uploadBtn.current.focus();
      event.preventDefault();
      return;
    }

    if (
      event.target === this.sendButton.current ||
      (event.target === this.closeButton.current && this.isSendDisabled())
    ) {
      this.uploadBtn.current.focus();
      event.preventDefault();
    }
  };

  onESC = (event) => {
    if (event.keyCode === 27 || event.which === 27 || event.key === "Escape") {
      this.modalHide();
    }
  };

  dropzonePart = () => {
    const fileNotLoaded = this.state.sending === 0;
    const fileLoading = this.state.sending === 1;
    const fileLoaded = this.state.sending === 2;
    const progressClass = classNames({
      "FileDropzone-progress": true,
      "is-attached": fileLoaded,
    });

    const dropZoneClass = classNames({
      "FileDropzone DashedBorders ": true,
      loaded: this.isSendingDone(),
      unloaded: !this.isSendingDone(),
      "step-error": this.isSendError(),
      dragged: this.state.dragged,
    });

    const dropZoneClassMobile = classNames({
      "FileDropzone FileDropzone--mobile ": true,
      loaded: this.isSendingDone(),
      unloaded: !this.isSendingDone(),
      "step-error": this.isSendError(),
      dragged: this.state.dragged,
    });

    return (
      <div>
        <Dropzone
          ref={(el) => (this.dropzoneRef = el)}
          onDrop={this.onDrop}
          disableClick
          accept="application/pdf"
          onDragEnter={this.dragEnter}
          multiple={false}
          style={this.dropzoneStyle}
          onDragLeave={this.dragLeave}
          maxSize={5242880}
        >
          <MediaQuery minDeviceWidth={1025}>
            <div className={dropZoneClass}>
              <div className={progressClass} style={{ width: this.state.progress }} />
              {fileNotLoaded && (
                <div className="FileDropzone-unloaded">
                  <p>Drag and drop your CV (PDF) here</p>
                  <p className="Text--thinner">or</p>
                </div>
              )}
              {fileLoading && (
                <div className="FileDropzone-unloaded">
                  <p>Loading...</p>
                </div>
              )}
              {fileLoaded && (
                <section className="FileDropzone-loaded">
                  <h3 className="FileDropzone-loadedHeader">Your CV</h3>
                  {this.state.files.map((file, index) => (
                    <div className="FileDropzone-loadedContentWrapper" key={file.name}>
                      <span className="FileDropzone-loadedText">{file.name}</span>
                      <button
                        ref={index === 0 && this.uploadBtn}
                        aria-label="Replace attached file"
                        className="FileDropzone-removeBtn"
                        onClick={this.removeFiles}
                      />
                    </div>
                  ))}
                </section>
              )}
              {!fileLoading && (
                <button
                  ref={!this.state.files && this.uploadBtn}
                  aria-label="Select a file with your CV"
                  className="Btn-link"
                  onClick={this.openDropzone}
                >
                  Select a file with your CV
                </button>
              )}
            </div>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={1024}>
            <div className={dropZoneClassMobile}>
              <div className={progressClass} style={{ width: this.state.progress }} />
              {fileLoading && (
                <div className="FileDropzone-unloaded">
                  <p>Loading...</p>
                </div>
              )}
              {fileLoaded && (
                <section className="FileDropzone-loaded">
                  <h3 className="FileDropzone-loadedHeader">Your CV</h3>
                  {this.state.files.map((file) => (
                    <div className="FileDropzone-loadedContentWrapper" key={file.name}>
                      <span className="FileDropzone-loadedText">{file.name}</span>
                      <button className="FileDropzone-removeBtn" onClick={this.removeFiles} />
                    </div>
                  ))}
                </section>
              )}
              {fileNotLoaded && (
                <button className="FileDropzone-btn" onClick={this.openDropzone}>
                  <span>add pdf</span>
                </button>
              )}
            </div>
          </MediaQuery>
        </Dropzone>
      </div>
    );
  };

  render() {
    const errorVisible = this.state.phase === 3;

    return (
      <div>
        {this.props.cvModalOpen && (
          <div
            className="ModalBackground"
            onKeyDown={(e) => {
              this.onTab(e);
              this.onESC(e);
            }}
          >
            <div className="Modal Bg--theme">
              {this.isReadyToLoadFile() && (
                <div
                  className="Modal-content"
                  role="dialog"
                  aria-label="Job Application"
                  aria-modal="true"
                >
                  <h2 className="Modal-header">Almost there!</h2>
                  <div className="Modal-separated">{this.dropzonePart()}</div>
                  <p aria-live="polite" className="Modal-errorMessage">
                    {errorVisible &&
                      "Please attach your CV in PDF format with a maximum size of 5 MB"}
                  </p>
                  <ContactDetails
                    email={this.state.email}
                    handleEmailChange={this.handleEmailChange}
                    emailValidationMsg={this.state.emailValidationMsg}
                    name={this.state.name}
                    handleNameChange={this.handleNameChange}
                    nameValidationMsg={this.state.nameValidationMsg}
                    surname={this.state.surname}
                    handleSurnameChange={this.handleSurnameChange}
                    surnameValidationMsg={this.state.surnameValidationMsg}
                  />
                  {this.props.selectedOffer ? (
                    this.props.selectedOffer.showDatePicker ? (
                      <RecruitmentTask
                        date={this.state.date}
                        handleDateChange={this.handleDateChange}
                        dateValidationMsg={this.state.dateValidationMsg}
                        minDate={this.getCurrentDate()}
                        recruitmentTask={this.props.selectedOffer.recruitmentTask}
                      />
                    ) : null
                  ) : null}
                  <div className="Modal-consents">
                    <ConsentCheckbox
                      fieldIsRequired
                      label={{
                        for: "opt-in-privacy-policy",
                        consent: true,
                        checked: this.state.personalConsentForPrivacyPolicy,
                        handleClick: this.handleClickPersonalConsentForPrivacyPolicyCheckbox,
                      }}
                      input={{
                        id: "opt-in-privacy-policy",
                        name: "opt-in-privacy-policy",
                        checked: this.state.personalConsentForPrivacyPolicy,
                      }}
                      textExpander={{
                        isRequired: false,
                        consentText: consents.personalConsentForPrivacyPolicy,
                      }}
                    />
                    <ConsentCheckbox
                      fieldIsRequired
                      label={{
                        for: "opt-in-personal-data",
                        consent: true,
                        checked: this.state.personalConsentForFutureOffers,
                        handleClick: this.handleClickPersonalConsentForFutureOffersCheckbox,
                      }}
                      input={{
                        id: "opt-in-personal-data",
                        name: "opt-in-personal-data",
                        checked: this.state.personalConsentForFutureOffers,
                      }}
                      textExpander={{
                        isRequired: true,
                        consentText: consents.personalConsentForFutureOffers,
                      }}
                    />
                    <div className="ConsentCheckbox-text">
                      <span className="ConsentCheckbox-isRequired">*</span>
                      <span>Required fields</span>
                    </div>
                  </div>
                  <div className="Modal-buttonsWrapper">
                    <CloseModalButton
                      text="cancel"
                      handleClick={this.modalHide}
                      forwardedRef={this.closeButton}
                    />
                    <SendButton
                      text="apply"
                      action={this.sendCv}
                      disabled={this.isSendDisabled()}
                      forwardedRef={this.sendButton}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

ModalUploader.propTypes = propTypes;
export default withHistoryLocation(withNavigate(ModalUploader));
