import React, { Component } from "react";
import { Link } from "react-router-dom";
import SvgFooterIcon from "../SvgFooterIcon/SvgFooterIcon";
import SocialMedia from "../SocialMedia/SocialMedia";

import "./_footer.scss";

class Footer extends Component {
  render() {
    return (
      <footer
        className={`Footer${this.props.additionalClass ? " " + this.props.additionalClass : ""}`}
        aria-hidden={this.props.cvModalOpen}
      >
        <div className="Footer-cities">
          <section className="Footer-city">
            <h2 className="Footer-cityName">Wrocław</h2>
            <ul>
              <li className="Footer-contact-item">ul. Sucha 3</li>
              <li className="Footer-contact-item">50-086 Wrocław</li>
              <li className="Footer-contact-item">Poland</li>
              <li className="Footer-contact-phone">Phone: +48 71 79 82 692</li>
              <li className="Footer-contact-item">Email: info.pl@xebia.com</li>
              <li className="Footer-contact-item">NIP: 8971719181</li>
            </ul>
          </section>
          <section className="Footer-city">
            <h2 className="Footer-cityName">Rzeszów</h2>
            <ul>
              <li className="Footer-contact-item">ul. Generała Maczka 6</li>
              <li className="Footer-contact-item">35-234 Rzeszów</li>
              <li className="Footer-contact-item">Poland</li>
              <li className="Footer-contact-phone">Phone: +48 17 711 19 22</li>
              <li className="Footer-contact-item">Email: info.pl@xebia.com</li>
            </ul>
          </section>
          <section className="Footer-city">
            <h2 className="Footer-cityName">Gdańsk</h2>
            <ul>
              <li className="Footer-contact-item">ul. Jana z Kolna 11</li>
              <li className="Footer-contact-item">80-864 Gdańsk</li>
              <li className="Footer-contact-item">Poland</li>
              <li className="Footer-contact-phone">Phone: +48 725 735 735</li>
              <li className="Footer-contact-item">Email: info.pl@xebia.com</li>
            </ul>
          </section>
        </div>
        <div className="Footer-links">
          <div className="Footer-informations">
            <p>
              <span>
                Made with <SvgFooterIcon />
                by Xebia
              </span>
            </p>

            <ul className="Footer-list">
              <li>
                <Link
                  to="/privacy-policy"
                  className="Footer-privacyPolicy"
                  tabIndex={this.props.cookieBannerVisible || this.props.cvModalOpen ? "-1" : "0"}
                >
                  Privacy policy
                </Link>
              </li>

              <li>
                <a
                  href="https://xebia.com/"
                  className="Footer-link"
                  title="Xebia"
                  tabIndex={this.props.cookieBannerVisible || this.props.cvModalOpen ? "-1" : "0"}
                >
                  Digital Transformation Consultancy Services and Solutions
                </a>
              </li>

              <li>
                <a
                  href="https://www.pgs-soft.com/16years/"
                  className="Footer-link"
                  title="Xebia - anniversary website"
                  tabIndex={this.props.cookieBannerVisible || this.props.cvModalOpen ? "-1" : "0"}
                >
                  We are 16!
                </a>
              </li>
            </ul>
          </div>

          <SocialMedia
            color={this.props.iconsColor}
            additionalClass={
              this.props.sociaMediaadditionalClass ? this.props.sociaMediaadditionalClass : ""
            }
            cookieBannerVisible={this.props.cookieBannerVisible}
            cvModalOpen={this.props.cvModalOpen}
          />
        </div>
      </footer>
    );
  }
}

export default Footer;
