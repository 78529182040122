import React, { Component } from "react";
import "./_privacyPolicy.scss";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="PrivacyPolicy">
        <div className="PrivacyPolicy-contentWrapper">
          <h1 className="PrivacyPolicy-heading">Privacy Policy</h1>
          <div className="PrivacyPolicy-content">
            <p className="PrivacyPolicy-paragraph">
              {`Is your personal information important to you? We know it is. It’s important to us
              too. That’s why we respect your privacy and make sure that your personal data is only
              used by us with your consent.`}
            </p>
            <p className="PrivacyPolicy-paragraph">
              We want to assure you that our Website, i.e.
              <a href="/" className="PrivacyPolicy-link" tabIndex="0">
                &nbsp;https://xebia.com/career-poland/
              </a>
              &nbsp; , does not automatically collect any information about you, except for
              information contained in cookies. Your e-mail address is only associated with a cookie
              when you fill in a form on our Website. Based on this, our marketing-automation system
              gains an insight into your browsing behavior on our Website, dating back to before you
              filled in the form.
            </p>
            <p className="PrivacyPolicy-paragraph">
              We will write a little more about cookies in another part of the Privacy Policy.
            </p>
            <p className="PrivacyPolicy-paragraph">
              The Privacy Policy we have prepared aims to inform you about WHERE, WHEN, AND WHY we
              collect your personal data. It also specifies which type of data we process. In it,
              you will find information about what you can require from us and about your rights.
            </p>

            <h2 className="PrivacyPolicy-subheading">{`Let’s get started`}</h2>
            <p className="PrivacyPolicy-paragraph">
              {`GDPR – such a fashionable abbreviation nowadays. But what does it mean? It’s short for
              The General Data Protection Regulation (GDPR) (EU) 2016/679. It’s a regulation in EU
              law on data protection and privacy for all individuals within the European Union. It
              also addresses the export of personal data outside the EU. The GDPR aims primarily to
              give control to citizens and residents over their personal data and to simplify the
              regulatory environment for international business by unifying the regulation within
              the EU.`}
            </p>
            <p className="PrivacyPolicy-paragraph">
              When will GDPR be in effect? GDPR was adopted on 14 April 2016, and, after a two-year
              transition period, becomes enforceable on 25 May 2018.
            </p>
            <p className="PrivacyPolicy-paragraph">
              {`What is considered personal data? Any information regarding an identified or
              identifiable natural person is considered to be personal data. Personal data will be,
              for example, your name, surname, e-mail address, telephone number, the name of your
              Company, your job title, or your computer’s IP address.`}
            </p>
            <p className="PrivacyPolicy-paragraph">
              The Personal Data Controller or ADO is ourselves: Xebia sp. z o.o. Our headquarters is
              located at 3 Sucha Street in Wroclaw, Poland.
            </p>
            <p className="PrivacyPolicy-paragraph">
              In this Privacy Policy, when we use the term Website, we are referring to a website,
              which we manage, i.e.
              <a href="/" className="PrivacyPolicy-link" tabIndex="0">
                &nbsp;https://xebia.com/career-poland/
              </a>
            </p>

            <h2 className="PrivacyPolicy-subheading">Where do you get my data from?</h2>
            <p className="PrivacyPolicy-paragraph">
              We receive your data directly from you when you fill in a contact form on our Website,
              or when you send us a question.
            </p>

            <h2 className="PrivacyPolicy-subheading">What kind of data do you process?</h2>
            <p className="PrivacyPolicy-paragraph">
              We follow the principle of minimization, i.e., we only process the data which is
              necessary for us to achieve the purpose of processing, i.e., your name, surname,
              e-mail address, and optionally, your telephone number.
            </p>

            <h2 className="PrivacyPolicy-subheading">
              What is the purpose and legal basis for the processing of your personal data by us?
            </h2>
            <p className="PrivacyPolicy-paragraph">
              {`We also process your personal data for the purposes set out below, (based on Xebia’s legitimate business interests), which are:`}
            </p>
            <ul className="PrivacyPolicy-list">
              <li>replying to a question / message you have sent</li>
              <li>conducting statistical analyses</li>
            </ul>
            <p className="PrivacyPolicy-paragraph">
              If you give YOUR consent, we process your personal information in order to:
            </p>
            <ul className="PrivacyPolicy-list">
              <li>
                contact you, including for purposes related to authorized marketing activities,
                through available communication channels, in particular, by e-mail and telephone
              </li>
              <li>send you commercial information via electronic means of communication</li>
            </ul>
            <p className="PrivacyPolicy-paragraph">
              You can withdraw your consent to the processing of your personal data at any time, in
              the same way as you expressed it. We will process your personal information until you
              withdraw your consent.
            </p>

            <h2 className="PrivacyPolicy-subheading">
              Do you have to provide us with your personal details?
            </h2>
            <p className="PrivacyPolicy-paragraph">
              No, of course not. As we already mentioned, browsing our Website does not mean you
              must provide your personal data; however, if you do not enter your data, we will not
              be able to deal with your query.
            </p>

            <h2 className="PrivacyPolicy-subheading">
              Do we transfer your data to countries outside the European Economic Area?
            </h2>
            <p className="PrivacyPolicy-paragraph">
              Your personal data will be transferred outside the European Economic Area, i.e. to
              technological platforms used for data integration and analysis as well as marketing
              and advertising activities; however, these platforms guarantee a high level of
              personal data protection. These providers are able to guarantee the highest level of
              data security because they are obliged to use standard contractual clauses adopted by
              the Commission (EC) or to participate in the Privacy-Shield programme established by
              the Commission for Implementing Decisions (EU) 2016/1250 of 12 July 2016 on the
              adequacy of protection provided by the EU-US Privacy Shield.
            </p>

            <h2 className="PrivacyPolicy-subheading">
              Do we process your personal data automatically (including through profiling) in a way
              that affects your rights?
            </h2>
            <p className="PrivacyPolicy-paragraph">
              Your personal data will be processed in an automated way (including in the form of
              profiling); however, it will not result in any legal consequences for you or
              substantially affect your situation.
            </p>
            <p className="PrivacyPolicy-paragraph">
              Profiling means the processing of personal data by means of using your personal data
              to evaluate some of your features. This data includes the country and city from which
              you are visiting our Website, your IP address, browser, and the operating system of
              the device from which you visit our Website.
            </p>
            <p className="PrivacyPolicy-paragraph">
              {`However, we wish to assure you that we do not make automated decisions in relation to
              you or your personal data. We only practice profiling in order to better match the
              content we show you and to create profiles for the Website’s users.`}
            </p>

            <h2 className="PrivacyPolicy-subheading">
              What are your rights concerning Xebia sp. z o.o. in terms of the processing of your
              personal data?
            </h2>
            <p className="PrivacyPolicy-paragraph">We want you to know that you are entitled to:</p>
            <ul className="PrivacyPolicy-list">
              <li>
                access your data and to receive copies of said data. You have the right to obtain
                confirmation from us as to whether we are processing your personal data, and, if
                this is the case, you have the right to:
                <ul className="PrivacyPolicy-list PrivacyPolicy-list--inner">
                  <li>gain access to your personal data,</li>
                  <li>
                    obtain information about the purposes of the processing, the categories of
                    personal data being processed, the recipients or categories of recipients of
                    this data, the planned period of storage of your data or criteria used for
                    determining this period, about your rights under the GDPR, and the right to
                    lodge a complaint against the supervisory body, about the source of said data,
                    about automated decision-making, including profiling, and the safeguards applied
                    in connection with the transfer of this data outside the European Union,
                  </li>
                  <li>obtain a copy of your personal data,</li>
                </ul>
              </li>
              <li>
                to rectify (correct) the data. You have the right to request that we correct your
                personal data (if it is incorrect) and supplement it (if it is incomplete)
              </li>
              <li>
                to delete the data (the right to be forgotten) - if in your opinion there is no
                reason for us to process your data, you can request that we remove it. You have the
                right to request the deletion of your personal data if:
                <ul className="PrivacyPolicy-list PrivacyPolicy-list--inner">
                  <li>
                    you withdraw your consent to the extent to which personal data was being
                    processed based on your original consent,
                  </li>
                  <li>
                    your personal data has ceased to be necessary for the purposes for which it was
                    collected or processed,
                  </li>
                  <li>you object to the use of your data for marketing purposes,</li>
                  <li>
                    {`you object to the use of your data for Website statistics and for user
                    satisfaction analysis – and your opposition is justified,`}
                  </li>
                  <li>your personal data has been processed unlawfully,</li>
                </ul>
                <p className="PrivacyPolicy-paragraph PrivacyPolicy-paragraph--inner">
                  Despite your request to delete your personal data, in connection with your
                  opposition or withdrawal of consent, we may retain certain details of your
                  personal data, which would be necessary to establish, assert, or defend claims.
                  This applies in particular to personal data including your name, surname, e-mail
                  address, and claims related to the use of our services.
                </p>
              </li>
              <li>
                data processing limitations - you may request that we limit the processing of your
                personal data only to store or perform the actions agreed on with you, if in your
                opinion we are in possession of incorrect data about you, or process it
                unjustifiably, or you do not want us to remove it because you need it to assert,
                determine, or defend claims, or you need it during the time of your opposition to
                the data processing. You have the right to request restrictions on the use of your
                personal data in the following cases:
                <ul className="PrivacyPolicy-list PrivacyPolicy-list--inner">
                  <li>
                    {`when you question the correctness of your personal data – in such a case, we
                    will limit its use for the time needed to verify the accuracy of your data,`}
                  </li>
                  <li>
                    if the processing of your data is against the law, and, instead of removing the
                    data, you demand restrictions on its use,
                  </li>
                  <li>
                    where your personal information has ceased to be necessary for the purposes for
                    which we have collected or used it, but it is necessary for you to determine,
                    assert, or defend claims,
                  </li>
                  <li>
                    {`if you object to the use of your data – in such a case, the restriction occurs
                    for the time needed to consider whether, (due to your individual situation), the
                    protection of your interests, rights, and freedoms outweighs our interest in
                    processing your personal data,`}
                  </li>
                </ul>
              </li>
              <li>
                to object to the processing of your data for the purposes of conducting direct
                marketing. If you exercise this right, we will stop processing your data for this
                purpose. If your objection turns out to be well founded, and we have no other legal
                basis to process your personal data, we will delete your personal information,
              </li>
              <li>
                {`to transfer data - you have the right to receive from us – in a structured, commonly
                used, machine-readable format, e.g. CSV, – the personal information you provided to
                us based on your consent. You may also ask us to send this data directly to another
                entity,`}
              </li>
              <li>
                to lodge a complaint against the supervisory body. You can lodge complaints, ask
                questions, and make requests regarding the processing of your personal data and the
                right to exercise your rights. If you feel that we are processing your data
                unlawfully, you can file a complaint to the supervisory body, i.e. The Bureau of the
                Inspector General for the Protection of Personal Data located at 2 Stawki Street in
                Warsaw, Poland.
              </li>
            </ul>

            <h2 className="PrivacyPolicy-subheading">
              With whom do we share your personal information?
            </h2>
            <p className="PrivacyPolicy-paragraph">
              We can disclose your data only if you give your consent for us to do so, or, if
              necessary, to authorized entities. Additionally, those of our employees who must have
              access to your data in order to perform their daily professional duties will have
              access to your data. Our subcontractors, i.e., entities we use to process your data,
              will also have access to your data. The said subcontractors are technology platforms
              used for integrating and analyzing data, and conducting marketing and advertising
              activities.
            </p>

            <h2 className="PrivacyPolicy-subheading">For how long do we store your data?</h2>
            <p className="PrivacyPolicy-paragraph">
              {`The personal data we process, such as your name and e-mail address, telephone number,
              company name, and job title, will be kept until you object to it being processed. If
              you’d like us to delete your data, you should withdraw your consent. Otherwise, we
              might decide to terminate the processing of your data if we determine that the consent
              received from you has become obsolete.`}
            </p>

            <h2 className="PrivacyPolicy-subheading">How can you contact us?</h2>
            <p className="PrivacyPolicy-paragraph">
              {`If you’d like to contact us, you can do so by filling in the contact form on our
              Website, or by writing to us at`}
              <a href="mailto:gdpr.pl@xebia.com" className="PrivacyPolicy-mail" tabIndex="0">
                &nbsp;gdpr.pl@xebia.com
              </a>
            </p>

            <h2 className="PrivacyPolicy-subheading" id="PrivacyPolicy-cookies">
              Cookies Policy
            </h2>
            <ul className="PrivacyPolicy-list PrivacyPolicy-list--first">
              <li>
                The Website does not automatically collect any information about you, except for
                information contained in cookies.
              </li>
              <li>
                {`Cookie files ("cookies") are IT data units, in particular, text files which are
                stored on the Website’s end user’s device, and are intended to be used on the
                Website’s pages. Cookies usually contain the name of the website from which they
                originate, their storage time on the end user’s device, and a unique number.`}
              </li>
              <li>
                {`The entity which places cookies on the end user's device and gains access to them is
                Xebia sp. z o.o.`}
              </li>
              <li>
                {`Based on cookies, our marketing automation system observes your behavior on the
                Website, such as, for example, which of the Website’s pages you visited, how long
                the visit lasted, what kind of content you downloaded, and which forms you filled
                in.`}
              </li>
              <li>
                Until you enter your e-mail address, your activities on the Website are recorded
                anonymously, (we do not know who you are, but we know, for example, which country
                you are from, which type of browser you use, and which type of device you use to
                browse the Website).
              </li>
              <li>
                {`Cookies are used to adjust the content of the Website’s pages to the end-user's
                preferences, and to optimize the use of the Website; in particular, these files
                allow us to recognize the Website user's device, which consequently allows us to
                properly display the Website in a manner tailored to the end-user’s individual
                needs. They also allow us to create statistics, which help us understand how end
                users browse the Website, which allows us to improve its structure and content.`}
              </li>
              <li>
                {`The Website may use two basic types of cookies: "session" and "persistent" cookies.
                Session cookies are temporary files, which are stored on the user's end device until
                they log out, leave the Website, or turn off the software (web browser). Persistent
                cookies are stored in the user's end device for the time specified in the
                cookie-file parameters or until they are removed by the user.`}
              </li>
              <li>
                The following types of cookies may be used as part of the Website:
                <ul className="PrivacyPolicy-list PrivacyPolicy-list--inner">
                  <li>
                    {`"necessary" cookies, which facilitate the use of services available on the
                    Website, e.g. authentication cookies used for services which require
                    authentication on the Website,`}
                  </li>
                  <li>
                    cookies used to ensure security, e.g. used to detect fraud in the field of
                    authentication on the Website,
                  </li>
                  <li>
                    {`"performance" cookies, which enable the collection of information regarding the
                    use of the Website’s pages,`}
                  </li>
                  <li>
                    {`"functional" cookies, which allow settings selected by the end-user to be
                    “remembered” and for the end-user’s interface to be personalized, e.g. the
                    end-user’s selected language, region, font size, website appearance, etc.,`}
                  </li>
                  <li>
                    {`In many cases, software used for browsing websites (web browser) allows cookies
                    to be stored in the end-user's device by default. Website users may change their
                    cookie settings at any time. These settings can be changed in the web browser’s
                    settings in such a way as to block the automatic handling of cookies, or to
                    inform the end users about each time the cookies are being activated on the
                    Website user’s device. Detailed information regarding how cookies can be handled
                    is available in the software (web browser) settings,`}
                  </li>
                  <li>
                    The Website operator states that restrictions on the use of cookies can affect
                    some of the functionalities available on the Website,
                  </li>
                  <li>
                    {`Cookies placed on the Website user's device may also be used by advertisers and
                    partners cooperating with the Website operator,`}
                  </li>
                  <li>
                    {`More information about cookies can be found in the "Settings" or "Help" section
                    of your browser's menu,`}
                  </li>
                </ul>
              </li>
            </ul>

            <h2 className="PrivacyPolicy-subheading">Trademarks & Copyrights</h2>
            <ul className="PrivacyPolicy-list PrivacyPolicy-list--first">
              <li>
                Unless otherwise stated, the copyrights to pages, information, materials, and their
                deployment within the Website, belong to Xebia sp. z o.o., or have been transferred
                with the appropriate license to Xebia sp. z o.o.
              </li>
              <li>
                The Website also contains trademarks, trade names, and logos reserved both by Xebia
                sp. z o.o., and by companies and third parties. The trademarks, trade names, and
                logos have been used only to identify them. By making the trademarks, trade names,
                and logos available on the Website, Xebia sp. z o.o. does not grant a license to use
                license to use them in any way.
              </li>
              <li>
                Users may print, duplicate, copy, or temporarily store, parts of the Website for
                their own purposes, or in connection with the use of Xebia sp. z o.o. products and
                services.
              </li>
              <li>{`Users are not allowed to make any changes to the Website’s content.`}</li>
              <li>
                The detailed scope of the permitted and unlawful use of Website content is regulated
                by law, in particular, the Act of 4 February 1994 on copyright and related rights
                (Journal of Laws of 1994, No. 24, item 83, as amended). Violation of these rights
                can result in civil and/or criminal sanctions.
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
